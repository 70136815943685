.blogpostpage {
  ul {
    list-style-position: inside !important;
  }

  img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  h1, h2, h3, h4, h5, h6 {
    strong {
      font-weight: inherit;
    }
  }
}