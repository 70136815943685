@import '../../variables.scss';

.blogPostCard {
  margin-bottom: 20px;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  height: calc(50em + 20px);
  position: relative;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  a {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .image {
    width: 100%;
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
  }

  h4 {
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .tags {
    font-size: 0.8em;
    margin-bottom: 12px;
  }

  .date {
    font-size: 0.8em;
    margin-bottom: 5px;
  }

  p {
    font-size: 0.8em;
    margin-bottom: 10px;
    line-height: 1.6em;
  }

  .blogCTA {
    color: $mainColor;
    text-align: center;
    font-weight: bold;
  }
}