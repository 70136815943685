@import '../../variables.scss';

.posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
}

@media (max-width: 600px) {
    .posts {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}